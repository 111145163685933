export default [
  {
    header: "Pages",
  },
  {
    title: "Dashboards",
    icon: "HomeIcon",
    route: "dashboard-ecommerce",
    typeUser: ["all"],
  },
  {
    title: "Admin Clients",
    icon: "UsersIcon",
    typeUser: ["Root", "admin"],
    children: [
      {
        title: "users",
        route: "users-list",
        typeUser: ["Root", "admin"],
      },
      {
        title: "player",
        route: "players-list",
        typeUser: ["Root", "admin"],
      },
      {
        title: "customer",
        route: "customer-list",
        typeUser: ["Root"],
      },
      {
        title: "Currencies",
        route: "currencies-list",
        typeUser: ["Root", "admin"],
      },
    ],
  },
  {
    title: "providers",
    icon: "UsersIcon",
    typeUser: ["Root", "admin"],
    children: [
      {
        title: "products",
        route: "products-list",
        typeUser: ["Root", "admin"],
      },
      {
        title: "games",
        route: "games-list",
        typeUser: ["Root", "admin"],
      },
      {
        title: "brands",
        route: "apps-brands",
        typeUser: ["Root"],
      },
      {
        title: "games_categories",
        route: "apps-games-categories",
        typeUser: ["Root"],
      },
    ],
  },
  {
    title: "Tags",
    icon: "HashIcon",
    children: [
      // {
      //   title: "Tags",
      //   route: "tags",
      //   typeUser: ["all"],
      // },
      {
        title: "Games",
        route: "tags.games",
        typeUser: ["all"],
      },
    ],
    typeUser: ["Root"],
  },
  {
    title: "reports",
    icon: "FileTextIcon",
    typeUser: ["Root", "admin", "operator"],
    children: [
      {
        title: "plays",
        route: "reports-plays",
        typeUser: ["all"],
      },
      // {
      //     title: 'log_debug',
      //     route: 'reports-log-debug',
      //     typeUser: ['Root']
      // },
      {
        title: "products_by_users",
        route: "profit-by-users",
        typeUser: ["all"],
      },
      {
        title: "Products By Games",
        route: "products-by-games",
        typeUser: ["all"],
      },
      {
        title: "By Currency",
        route: "by-currency",
        typeUser: ["all"],
      },
      {
        title: "Most Played",
        route: "most-played",
        typeUser: ["all"],
      },
      {
        title: "Products",
        route: "products",
        typeUser: ["all"],
      },
      {
        title: "Products By Brands",
        route: "products-by-brands",
        typeUser: ["all"],
      },
      {
        title: "Poker",
        route: "reports-poker",
        typeUser: ["all"],
      },
      {
        title: "Poker V2",
        route: "reports-poker-v2",
        typeUser: ["Root"],
      },
      {
        title: "Poker Detailed By User",
        route: "reports-poker-detailed-by-user",
        typeUser: ["all"],
      },
      {
        title: "synchronize_reports",
        route: "synchronizeReports",
        typeUser: ["Root"],
      },
    ],
  },
  {
    title: "Logs",
    icon: "AlertOctagonIcon",
    typeUser: ["Root", "admin"],
    children: [
      {
        title: "logs V2",
        route: "logs-V2",
        typeUser: ["Root"],
      },
      {
        title: "log_connections",
        route: "reports-log-connections",
        typeUser: ["Root"],
      },
      {
        title: "log_request",
        route: "reports-log-request",
        typeUser: ["Root", "admin"],
      },
      {
        title: "log_error",
        route: "reports-log-error",
        typeUser: ["Root"],
      },
      {
        title: "log_launch",
        route: "reports-log-launch",
        typeUser: ["Root"],
      },
    ],
  },
  {
    title: "sports",
    icon: "FileTextIcon",
    typeUser: ["Root"],
    children: [
      {
        // Betcore Banners
        title: "BetPrime",
        route: "apps-betprime",
        typeUser: ["Root"],
      },
      {
        // Ticket sportsbook
        title: "ticket_sportsbook",
        route: "apps-ticket-sportsbook",
        typeUser: ["Root"],
      },
    ],
  },
  {
    title: "bonus",
    icon: "FileTextIcon",
    typeUser: ["Root"],
    children: [
      {
        // Ticket sportsbook
        title: "segments",
        route: "segments",
        typeUser: ["Root"],
      },
      {
        // Betcore Banners
        title: "sync-games",
        route: "sync-games",
        typeUser: ["Root"],
      },
      // games-promotion
      {
        // Betcore Banners
        title: "games-promotion",
        route: "games-promotion",
        typeUser: ["Root"],
      },
    ],
  },
];
